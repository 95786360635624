@import "https://fonts.googleapis.com/css?family=Roboto+Mono:100";
.container[data-v-6806ae] {
  height: 100%;
  width: 100%;
  background: #212121;
  justify-content: center;
  align-items: center;
  font-family: Roboto Mono, monospace;
  display: flex;
  position: absolute;
}

.text[data-v-6806ae] {
  color: #fafafa;
  font-size: 10vw;
  font-weight: 100;
}

.glitchedLoading .gw-glitched {
  color: #757575;
}

.container[data-v-f78eb7] {
  height: 25vh;
  width: 100%;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, .98) 0%, rgba(0, 0, 0, .58) 100%);
  padding-top: 5vh;
  font-family: Roboto Mono, monospace;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

.text[data-v-f78eb7] {
  color: #fafafa;
  font-weight: 100;
}

.title[data-v-f78eb7] {
  font-size: 10vmin;
}

.email[data-v-f78eb7] {
  font-size: 5vmin;
}

a[data-v-f78eb7] {
  text-decoration: none;
}

.glitchedEffect .text {
  color: #a5e5d4;
  will-change: contents, width;
  text-shadow: 2px 4px 10px rgba(165, 229, 212, .5);
  font-family: "M PLUS 1p", Open Sans, sans-serif;
  position: relative;
}

.glitchedEffect .text:after, .glitchedEffect .text:before {
  content: attr(data-gw-string);
  opacity: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  color: #42c3c8;
  will-change: contents, width;
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.glitchedEffect .text:before {
  z-index: -1;
  color: #d93da5;
}

.glitchedEffect .text.gw-writing {
  animation: 1s steps(10, end) infinite alternate-reverse glitch-skew;
}

.glitchedEffect .text.gw-writing .gw-ghosts, .glitchedEffect .text.gw-writing .gw-glitched {
  opacity: .6;
  animation: 1s steps(20, end) infinite alternate-reverse glitch-blink;
}

.glitchedEffect .text.gw-writing:after, .glitchedEffect .text.gw-writing:before {
  opacity: 1;
}

.glitchedEffect .text.gw-writing:after {
  animation: 1.5s steps(20, end) infinite alternate-reverse glitch-animation-1;
}

.glitchedEffect .text.gw-writing:before {
  animation: 2s steps(20, end) infinite alternate-reverse glitch-animation-2;
}

@keyframes glitch-skew {
  0% {
    transform: skew(4deg);
  }

  10% {
    transform: skew(2deg);
  }

  20% {
    transform: skew(0deg);
  }

  30% {
    transform: skew(2deg);
  }

  40% {
    transform: skew(-2deg);
  }

  50% {
    transform: skew(4deg);
  }

  60% {
    transform: skew(-2deg);
  }

  70% {
    transform: skew(-1deg);
  }

  80% {
    transform: skew(0deg);
  }

  90% {
    transform: skew(-4deg);
  }

  100% {
    transform: skew(0deg);
  }
}

@keyframes glitch-blink {
  0% {
    opacity: .5;
  }

  5% {
    opacity: .9;
  }

  10% {
    opacity: 1;
  }

  15% {
    opacity: 1;
  }

  20% {
    opacity: .7;
  }

  25% {
    opacity: .5;
  }

  30% {
    opacity: .2;
  }

  35% {
    opacity: .3;
  }

  40% {
    opacity: .5;
  }

  45% {
    opacity: .4;
  }

  50% {
    opacity: .5;
  }

  55% {
    opacity: .7;
  }

  60% {
    opacity: .5;
  }

  65% {
    opacity: .5;
  }

  70% {
    opacity: .1;
  }

  75% {
    opacity: .8;
  }

  80% {
    opacity: .8;
  }

  85% {
    opacity: .4;
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: .1;
  }

  100% {
    opacity: .7;
  }
}

@keyframes glitch-animation-1 {
  0% {
    clip: rect(43px, 1000px, 57px, 0);
    transform: skew(6deg)translateX(9px);
  }

  5% {
    clip: rect(15px, 1000px, 95px, 0);
    transform: skew(4deg)translateX(10px);
  }

  10% {
    clip: rect(2px, 1000px, 42px, 0);
    transform: skew(-7deg)translateX(-10px);
  }

  15% {
    clip: rect(25px, 1000px, 92px, 0);
    transform: skew(-2deg)translateX(14px);
  }

  20% {
    clip: rect(14px, 1000px, 81px, 0);
    transform: skew(2deg)translateX(-10px);
  }

  25% {
    clip: rect(87px, 1000px, 82px, 0);
    transform: skew(6deg)translateX(-5px);
  }

  30% {
    clip: rect(20px, 1000px, 80px, 0);
    transform: skew(-5deg)translateX(0);
  }

  35% {
    clip: rect(27px, 1000px, 52px, 0);
    transform: skew(-1deg)translateX(-11px);
  }

  40% {
    clip: rect(33px, 1000px, 28px, 0);
    transform: skew(5deg)translateX(-9px);
  }

  45% {
    clip: rect(81px, 1000px, 13px, 0);
    transform: skew(3deg)translateX(15px);
  }

  50% {
    clip: rect(51px, 1000px, 47px, 0);
    transform: skew(-4deg)translateX(3px);
  }

  55% {
    clip: rect(37px, 1000px, 38px, 0);
    transform: skew(-2deg)translateX(-12px);
  }

  60% {
    clip: rect(34px, 1000px, 35px, 0);
    transform: skew(-1deg)translateX(0);
  }

  65% {
    clip: rect(56px, 1000px, 37px, 0);
    transform: skew(2deg)translateX(-10px);
  }

  70% {
    clip: rect(9px, 1000px, 41px, 0);
    transform: skew(2deg)translateX(-3px);
  }

  75% {
    clip: rect(66px, 1000px, 97px, 0);
    transform: skew(-1deg)translateX(-12px);
  }

  80% {
    clip: rect(33px, 1000px, 62px, 0);
    transform: skew(8deg)translateX(7px);
  }

  85% {
    clip: rect(66px, 1000px, 17px, 0);
    transform: skew(-2deg)translateX(-6px);
  }

  90% {
    clip: rect(47px, 1000px, 54px, 0);
    transform: skew(-2deg)translateX(-12px);
  }

  95% {
    clip: rect(61px, 1000px, 65px, 0);
    transform: skew(-6deg)translateX(2px);
  }

  100% {
    clip: rect(19px, 1000px, 36px, 0);
    transform: skew(-6deg)translateX(10px);
  }
}

@keyframes glitch-animation-2 {
  0% {
    clip: rect(59px, 1000px, 93px, 0);
    transform: skew(-1deg)translateX(-4px);
  }

  5% {
    clip: rect(89px, 1000px, 3px, 0);
    transform: skew(2deg)translateX(4px);
  }

  10% {
    clip: rect(12px, 1000px, 21px, 0);
    transform: skew(3deg)translateX(3px);
  }

  15% {
    clip: rect(62px, 1000px, 66px, 0);
    transform: skew(-1deg)translateX(10px);
  }

  20% {
    clip: rect(24px, 1000px, 77px, 0);
    transform: skew(5deg)translateX(9px);
  }

  25% {
    clip: rect(62px, 1000px, 10px, 0);
    transform: skew(2deg)translateX(-2px);
  }

  30% {
    clip: rect(28px, 1000px, 14px, 0);
    transform: skew(0deg)translateX(10px);
  }

  35% {
    clip: rect(84px, 1000px, 44px, 0);
    transform: skew(2deg)translateX(0);
  }

  40% {
    clip: rect(91px, 1000px, 81px, 0);
    transform: skew(-2deg)translateX(5px);
  }

  45% {
    clip: rect(54px, 1000px, 63px, 0);
    transform: skew(-1deg)translateX(-1px);
  }

  50% {
    clip: rect(23px, 1000px, 8px, 0);
    transform: skew(1deg)translateX(6px);
  }

  55% {
    clip: rect(4px, 1000px, 74px, 0);
    transform: skew(-4deg)translateX(2px);
  }

  60% {
    clip: rect(3px, 1000px, 32px, 0);
    transform: skew(-4deg)translateX(10px);
  }

  65% {
    clip: rect(72px, 1000px, 37px, 0);
    transform: skew(-1deg)translateX(3px);
  }

  70% {
    clip: rect(71px, 1000px, 98px, 0);
    transform: skew(2deg)translateX(7px);
  }

  75% {
    clip: rect(63px, 1000px, 40px, 0);
    transform: skew(2deg)translateX(8px);
  }

  80% {
    clip: rect(73px, 1000px, 30px, 0);
    transform: skew(5deg)translateX(-5px);
  }

  85% {
    clip: rect(63px, 1000px, 83px, 0);
    transform: skew(4deg)translateX(1px);
  }

  90% {
    clip: rect(35px, 1000px, 70px, 0);
    transform: skew(-2deg)translateX(2px);
  }

  95% {
    clip: rect(18px, 1000px, 44px, 0);
    transform: skew(-1deg)translateX(-4px);
  }

  100% {
    clip: rect(70px, 1000px, 41px, 0);
    transform: skew(2deg)translateX(4px);
  }
}

#canvas[data-v-f64a35] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

main[data-v-f64a35] {
  width: 100%;
  height: 900vh;
  z-index: 99;
  text-align: center;
  pointer-events: none;
  justify-content: center;
  font-size: 5vh;
  position: absolute;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: #000;
  margin: 0;
  padding: 0;
}



@keyframes fromdown {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fromdown-enter-active {
  animation: .5s fromdown;
}

.fromdown-leave-active {
  animation: .5s reverse fromdown;
}

.bouncefade-enter-active, .bouncefade-leave-active {
  transition: all 1s;
}

.bouncefade-enter-from, .bouncefade-leave-to {
  opacity: 0;
  transform: scale(2);
}

::-webkit-scrollbar {
  width: 17px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  background-clip: content-box;
  border: 6px solid rgba(0, 0, 0, 0);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

* {
  overflow-y: overlay;
  overflow-x: hidden;
}

.container[data-v-6806ae] {
  height: 100%;
  width: 100%;
  background: #212121;
  justify-content: center;
  align-items: center;
  font-family: Roboto Mono, monospace;
  display: flex;
  position: absolute;
}

.text[data-v-6806ae] {
  color: #fafafa;
  font-size: 10vw;
  font-weight: 100;
}

.glitchedLoading .gw-glitched {
  color: #757575;
}

.container[data-v-f78eb7] {
  height: 25vh;
  width: 100%;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, .98) 0%, rgba(0, 0, 0, .58) 100%);
  padding-top: 5vh;
  font-family: Roboto Mono, monospace;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

.text[data-v-f78eb7] {
  color: #fafafa;
  font-weight: 100;
}

.title[data-v-f78eb7] {
  font-size: 10vmin;
}

.email[data-v-f78eb7] {
  font-size: 5vmin;
}

a[data-v-f78eb7] {
  text-decoration: none;
}

.glitchedEffect .text {
  color: #a5e5d4;
  will-change: contents, width;
  text-shadow: 2px 4px 10px rgba(165, 229, 212, .5);
  font-family: "M PLUS 1p", Open Sans, sans-serif;
  position: relative;
}

.glitchedEffect .text:after, .glitchedEffect .text:before {
  content: attr(data-gw-string);
  opacity: 0;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  color: #42c3c8;
  will-change: contents, width;
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.glitchedEffect .text:before {
  z-index: -1;
  color: #d93da5;
}

.glitchedEffect .text.gw-writing {
  animation: 1s steps(10, end) infinite alternate-reverse glitch-skew;
}

.glitchedEffect .text.gw-writing .gw-ghosts, .glitchedEffect .text.gw-writing .gw-glitched {
  opacity: .6;
  animation: 1s steps(20, end) infinite alternate-reverse glitch-blink;
}

.glitchedEffect .text.gw-writing:after, .glitchedEffect .text.gw-writing:before {
  opacity: 1;
}

.glitchedEffect .text.gw-writing:after {
  animation: 1.5s steps(20, end) infinite alternate-reverse glitch-animation-1;
}

.glitchedEffect .text.gw-writing:before {
  animation: 2s steps(20, end) infinite alternate-reverse glitch-animation-2;
}

@keyframes glitch-skew {
  0% {
    transform: skew(-3deg);
  }

  10% {
    transform: skew(-2deg);
  }

  20% {
    transform: skew(5deg);
  }

  30% {
    transform: skew(0deg);
  }

  40% {
    transform: skew(-3deg);
  }

  50% {
    transform: skew(5deg);
  }

  60% {
    transform: skew(5deg);
  }

  70% {
    transform: skew(-1deg);
  }

  80% {
    transform: skew(4deg);
  }

  90% {
    transform: skew(0deg);
  }

  100% {
    transform: skew(0deg);
  }
}

@keyframes glitch-blink {
  0% {
    opacity: .9;
  }

  5% {
    opacity: .3;
  }

  10% {
    opacity: 1;
  }

  15% {
    opacity: .4;
  }

  20% {
    opacity: 1;
  }

  25% {
    opacity: .8;
  }

  30% {
    opacity: .8;
  }

  35% {
    opacity: .9;
  }

  40% {
    opacity: 1;
  }

  45% {
    opacity: .3;
  }

  50% {
    opacity: .9;
  }

  55% {
    opacity: .3;
  }

  60% {
    opacity: .5;
  }

  65% {
    opacity: .3;
  }

  70% {
    opacity: .1;
  }

  75% {
    opacity: .1;
  }

  80% {
    opacity: 1;
  }

  85% {
    opacity: .8;
  }

  90% {
    opacity: .9;
  }

  95% {
    opacity: .6;
  }

  100% {
    opacity: .1;
  }
}

@keyframes glitch-animation-1 {
  0% {
    clip: rect(98px, 1000px, 67px, 0);
    transform: skew(-4deg)translateX(4px);
  }

  5% {
    clip: rect(37px, 1000px, 49px, 0);
    transform: skew(2deg)translateX(12px);
  }

  10% {
    clip: rect(25px, 1000px, 70px, 0);
    transform: skew(-3deg)translateX(-8px);
  }

  15% {
    clip: rect(4px, 1000px, 81px, 0);
    transform: skew(2deg)translateX(14px);
  }

  20% {
    clip: rect(81px, 1000px, 23px, 0);
    transform: skew(4deg)translateX(2px);
  }

  25% {
    clip: rect(58px, 1000px, 31px, 0);
    transform: skew(5deg)translateX(-1px);
  }

  30% {
    clip: rect(10px, 1000px, 61px, 0);
    transform: skew(-1deg)translateX(7px);
  }

  35% {
    clip: rect(77px, 1000px, 53px, 0);
    transform: skew(4deg)translateX(11px);
  }

  40% {
    clip: rect(91px, 1000px, 57px, 0);
    transform: skew(-2deg)translateX(-2px);
  }

  45% {
    clip: rect(83px, 1000px, 63px, 0);
    transform: skew(5deg)translateX(9px);
  }

  50% {
    clip: rect(23px, 1000px, 17px, 0);
    transform: skew(3deg)translateX(5px);
  }

  55% {
    clip: rect(56px, 1000px, 91px, 0);
    transform: skew(1deg)translateX(-10px);
  }

  60% {
    clip: rect(41px, 1000px, 89px, 0);
    transform: skew(3deg)translateX(2px);
  }

  65% {
    clip: rect(27px, 1000px, 82px, 0);
    transform: skew(3deg)translateX(7px);
  }

  70% {
    clip: rect(41px, 1000px, 92px, 0);
    transform: skew(0deg)translateX(-10px);
  }

  75% {
    clip: rect(11px, 1000px, 6px, 0);
    transform: skew(2deg)translateX(9px);
  }

  80% {
    clip: rect(40px, 1000px, 52px, 0);
    transform: skew(8deg)translateX(-9px);
  }

  85% {
    clip: rect(30px, 1000px, 45px, 0);
    transform: skew(7deg)translateX(9px);
  }

  90% {
    clip: rect(41px, 1000px, 46px, 0);
    transform: skew(-6deg)translateX(8px);
  }

  95% {
    clip: rect(23px, 1000px, 70px, 0);
    transform: skew(1deg)translateX(9px);
  }

  100% {
    clip: rect(38px, 1000px, 2px, 0);
    transform: skew(-2deg)translateX(-7px);
  }
}

@keyframes glitch-animation-2 {
  0% {
    clip: rect(7px, 1000px, 22px, 0);
    transform: skew(2deg)translateX(-5px);
  }

  5% {
    clip: rect(66px, 1000px, 91px, 0);
    transform: skew(5deg)translateX(-4px);
  }

  10% {
    clip: rect(40px, 1000px, 30px, 0);
    transform: skew(5deg)translateX(-2px);
  }

  15% {
    clip: rect(98px, 1000px, 91px, 0);
    transform: skew(5deg)translateX(2px);
  }

  20% {
    clip: rect(75px, 1000px, 9px, 0);
    transform: skew(1deg)translateX(-1px);
  }

  25% {
    clip: rect(54px, 1000px, 89px, 0);
    transform: skew(-2deg)translateX(6px);
  }

  30% {
    clip: rect(29px, 1000px, 20px, 0);
    transform: skew(5deg)translateX(3px);
  }

  35% {
    clip: rect(100px, 1000px, 4px, 0);
    transform: skew(-2deg)translateX(-8px);
  }

  40% {
    clip: rect(39px, 1000px, 83px, 0);
    transform: skew(5deg)translateX(-1px);
  }

  45% {
    clip: rect(84px, 1000px, 16px, 0);
    transform: skew(-2deg)translateX(-8px);
  }

  50% {
    clip: rect(39px, 1000px, 53px, 0);
    transform: skew(2deg)translateX(9px);
  }

  55% {
    clip: rect(91px, 1000px, 89px, 0);
    transform: skew(3deg)translateX(-9px);
  }

  60% {
    clip: rect(76px, 1000px, 66px, 0);
    transform: skew(1deg)translateX(8px);
  }

  65% {
    clip: rect(94px, 1000px, 84px, 0);
    transform: skew(-3deg)translateX(1px);
  }

  70% {
    clip: rect(92px, 1000px, 22px, 0);
    transform: skew(1deg)translateX(-2px);
  }

  75% {
    clip: rect(49px, 1000px, 22px, 0);
    transform: skew(-3deg)translateX(2px);
  }

  80% {
    clip: rect(36px, 1000px, 53px, 0);
    transform: skew(-1deg)translateX(1px);
  }

  85% {
    clip: rect(45px, 1000px, 44px, 0);
    transform: skew(5deg)translateX(8px);
  }

  90% {
    clip: rect(5px, 1000px, 28px, 0);
    transform: skew(0deg)translateX(9px);
  }

  95% {
    clip: rect(84px, 1000px, 3px, 0);
    transform: skew(-4deg)translateX(7px);
  }

  100% {
    clip: rect(10px, 1000px, 74px, 0);
    transform: skew(1deg)translateX(0);
  }
}

#canvas[data-v-f64a35] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

main[data-v-f64a35] {
  width: 100%;
  height: 900vh;
  z-index: 99;
  text-align: center;
  pointer-events: none;
  justify-content: center;
  font-size: 5vh;
  position: absolute;
}

/*# sourceMappingURL=index.a0059198.css.map */
